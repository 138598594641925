import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Dictionary, Entity, HasChildren, PlainObject, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { applyOpenGraphThumbnailSize, classNames } from '@shapeable/utils';
import { EntityMetaSection, EventDateLocation, MetaContainer, useLang } from '@shapeable/ui';
import { Event } from '@shapeable/gesda-types';
import { uniqBy, flatten, map, sortBy, isArray } from 'lodash';
import { sortConnectedTrends } from '../../utils/sort-connected-trends';
import { ExplorerMetaSectionTitle } from '@shapeable/ui';
const cls = classNames('event-aside-layout');

// -------- Types -------->

// -------- Props -------->

export type EventMetaLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventMetaLayoutDefaultProps: Omit<EventMetaLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(8)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: 1em;
    color: ${theme.COLOR('text-mid')};
    margin-bottom: ${theme.UNIT(4)};
  `,
});

const DatesAndLocationStyles = breakpoints({
  base: css`
    
  `,
});

const MetaSectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(4)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(MetaContainer)<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      Title: styled(ExplorerMetaSectionTitle)`${TitleStyles}`,
      DatesAndLocation: styled(EventDateLocation)`${DatesAndLocationStyles}`,
      MetaSection: styled(EntityMetaSection)`${MetaSectionStyles}`,
};

export const EventMetaLayout: Shapeable.FC<EventMetaLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const t = useLang();

  const { trends = [], topics = [], subTopics = [], connectedPages = [], sessions = [], roles = [],
    connectedEvents = [] } = entity;

  const allConnectedTrends = [...trends, ...topics, ...subTopics];

  const sortedConnectedTrends = sortConnectedTrends(allConnectedTrends);

  const groupRoles: Dictionary<Entity[]> = roles.reduce((acc: PlainObject, role) => {
      const roleName = role.role.name;

      // the schema has a mistake where "person" is a linkedMultiple. This is a workaround
      let person = role.person;

      if (isArray(person)) {
        /* @ts-ignore */
        person = person.map(applyOpenGraphThumbnailSize('bubble'));
      }


      return {
        ...acc,
        [roleName]: flatten([...(acc[roleName] || []), person]),
      }
  }, {});

  const sessionParticipants = (uniqBy(flatten(sessions.map(session => session.presenters.map(presenter => presenter))), 'id')).map(applyOpenGraphThumbnailSize('bubble'));

  const hasRoles = !!roles.length;
  const hasConnections = !!allConnectedTrends.length || !!connectedPages.length || !!connectedEvents.length;

  return (
    <My.Container className={cls.name(className)}>
      <My.Section>
        <My.DatesAndLocation entity={entity} />
      </My.Section>
      {
        hasRoles && (
          <My.Section>
            {
              map(groupRoles, (people, role) => (
                <My.MetaSection key={role} label={role} items={people} />
              ))
            }
          </My.Section>
        )
      }
      {
        !hasRoles && !!sessionParticipants.length && (
          <My.Section>
            <My.MetaSection label="Participants" items={sessionParticipants || []} showAllItems={false} />
          </My.Section>
        )
      }
      {
        hasConnections && (
          <My.Section>
            <My.Title>{t('Connections')}</My.Title>
            <My.MetaSection label="Pulse of Science" items={sortedConnectedTrends || []} spacing={4} />
            <My.MetaSection label="Pages" items={connectedPages || []} spacing={4}/>
            <My.MetaSection label="Pulse of Diplomacy" items={connectedEvents || []} />
          </My.Section>
        )
      }
     {children}
    </My.Container>
  )
};

EventMetaLayout.defaultProps = EventMetaLayoutDefaultProps;
EventMetaLayout.cls = cls;