import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { EventView } from '../../types';
import { ContentNode, Grid, PersonBioCell, useLang, useView, VideoImage } from '@shapeable/ui';
import { GptLayout } from '@shapeable/gpt';
import { EventSessionsLayout } from './event-session-layout';
import { Event } from '@shapeable/gesda-types';
import { useEventViewDefinition } from '../../hooks/use-event-view-definition';
const cls = classNames('event-view-layout');

// -------- Types -------->

// -------- Props -------->

export type EventViewLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventViewLayoutDefaultProps: Omit<EventViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(5)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(5)};
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    
  `,
});

const IntroStyles = breakpoints({
  base: css`
    font-weight: 300;
    line-height: 1.65em;
    color: ${theme.COLOR('strong')};
    font-size: ${18/16}em;
  `,
});

const ContentTitleStyles = breakpoints({
  base: css`
    font-weight: 500;
    margin-bottom: ${theme.UNIT(2)};
    font-size: ${21/16}em;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    font-weight: 300;
    font-size: ${15/14}em;
  `,
});

const VideoStyles = breakpoints({
  base: css`

  `,
});

const ParticipantsStyles = breakpoints({
  base: css`
    ${Grid.cls.child('item')} {
      align-items: flex-start;
      justify-content: center;
    }
  `,
});

const ParticipantStyles = breakpoints({
  base: css`
    .shp--badge {
      padding: 0 ${theme.UNIT(2)};
    }
  `,
});

const EventSessionsLayoutStyles = breakpoints({
  base: css`

  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
      GptLayout: styled(GptLayout)`${GptLayoutStyles}`,
      Intro: styled(ContentNode)`${IntroStyles}`,

      ContentTitle: styled.span`${ContentTitleStyles}`,
      Content: styled(ContentNode)`${ContentStyles}`,

      Video: styled(VideoImage)`${VideoStyles}`,

      Participants: styled(Grid)`${ParticipantsStyles}`,
        Participant: styled(PersonBioCell)`${ParticipantStyles}`,

    EventSessionsLayout: styled(EventSessionsLayout)`${EventSessionsLayoutStyles}`,
};

export const EventViewLayout: Shapeable.FC<EventViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { description, desiredOutcomes, keyTakeaways, overview, video } = entity;
  const { defaultView, hasDesiredOutcomes, hasKeyTakeaways, hasSessions, hasVideo } = useEventViewDefinition(entity);
  const { showView } = useView<EventView>(entity.slug, defaultView);

  const t = useLang();
  
  return (
    <My.Container className={cls.name(className)}>
      {
        showView('radar-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('key-takeaways') &&
        <>
          {
            hasVideo && (
              <My.Section>
                <My.Video entity={video} />
              </My.Section>
            )
          }
          <My.Section>
            <My.Content entity={keyTakeaways} />
          </My.Section>
        </>
      }
      {
        showView('overview') &&
        <>
          <My.Section>
            <My.Intro entity={overview} />
          </My.Section>
          
          <My.Section>
            <My.Content entity={description} />
          </My.Section>
        </>
      }
      {
        showView('sessions') &&
        <>
          <My.Section>
            <My.EventSessionsLayout entity={entity} />
          </My.Section>
          {
            hasDesiredOutcomes && (
              <My.Section>
                <My.ContentTitle>{t('Desired Outcomes')}</My.ContentTitle>
                <My.Content entity={desiredOutcomes} />
              </My.Section>
            )
          }
        </>
      }
  </My.Container>
  )
};

EventViewLayout.defaultProps = EventViewLayoutDefaultProps;
EventViewLayout.cls = cls;