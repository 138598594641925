import { Event } from "@shapeable/gesda-types"
import { EventView } from '../types';
import { isDatePast } from "@shapeable/utils";


type EventViewDefiniton = {
  hasDesiredOutcomes: boolean;
  hasKeyTakeaways: boolean;
  hasSessions: boolean;
  hasVideo: boolean;
  hasOverview: boolean;
  defaultView: EventView;
};

export const useEventViewDefinition: (event: Event) => EventViewDefiniton = 
  (entity: Event) => {
    const { keyTakeaways, video, overview, endDate, description, desiredOutcomes } = entity;
    const hasDesiredOutcomes = !!desiredOutcomes?.text;
    const hasKeyTakeaways = !!keyTakeaways?.text;
    const hasSessions = entity.sessions && entity.sessions.length > 0;
    const hasVideo = !!video?.url;
    const hasOverview = !!overview?.text || !!description?.text

    const defaultView: EventView = hasKeyTakeaways && isDatePast(endDate) ? 'key-takeaways' : (hasSessions ? 'sessions' : 'overview');

    return {
      hasDesiredOutcomes,
      hasKeyTakeaways,
      hasSessions,
      hasVideo,
      hasOverview,
      defaultView
    };
    
  };