import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, entityLabel, entityTypeNameFor } from '@shapeable/utils';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { EventView } from '../../types';
import { compact } from 'lodash';
import { Event } from '@shapeable/gesda-types';
import { useEventViewDefinition } from '../../hooks/use-event-view-definition';
const cls = classNames('event-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type EventTabsLayoutProps = Classable & HasChildren & { 
  entity?: Event;
};

export const EventTabsLayoutDefaultProps: Omit<EventTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const TabsStyles = breakpoints({
  base: css`
  
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,
};

export const EventTabsLayout: Shapeable.FC<EventTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color, isKeyTakeawaysReady, endDate } = entity;
  // const eventType = entityLabel(entity);
  // const isWorkshopEvent = eventType === 'Workshop';
  // const showSessions = isWorkshopEvent && hasSessions;

  // const isPastDate = endDate && new Date() > new Date(endDate);
  // const showKeyTakeaways = isPastDate && isKeyTakeawaysReady;

  const { defaultView, hasDesiredOutcomes, hasKeyTakeaways, hasOverview, hasSessions } = useEventViewDefinition(entity);

  const tabs: ContentTabSpec<EventView>[] = compact([
    {
      id: 'radar-ai',
      icon: <AiUpperCaseIconGlyph />,
    },
    hasKeyTakeaways &&
    {
      id: 'key-takeaways',
      label: 'Key Takeaways',
    },
    hasOverview &&
    {
      id: 'overview',
      label: 'Overview',
    },
    hasSessions &&
    {
      id: 'sessions',
      label: 'Sessions',
    },
  ]);
  
  const { activeView, setActiveView } = useView<EventView>(`event-${entity.slug}`, defaultView);
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value }} />
  )
};

EventTabsLayout.defaultProps = EventTabsLayoutDefaultProps;
EventTabsLayout.cls = cls;